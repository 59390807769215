@media (min-width: 768px) {
    #bottom-nav{
        width: 260px;
        background: #F6F6FC;
        border-top-width: 0;
        border-right-width: 1px;
        border-left-width: 0;
        border-bottom-width: 0;
        border-right-color: #9299BE;
        border-style: solid;
        padding-top: 20px;
    }
}

