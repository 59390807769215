.quill {
    border-radius: 8px;
    border: 1.5px solid #BBC2DC1A;
    padding: 2px 6px;
}

.ql-toolbar, .ql-container {
    border: unset !important;
}

.ql-toolbar {
    border-bottom: 1px solid #F1F1F1 !important;
}

.ql-container, .ql-editor {
    height: auto !important;
}
