@font-face {
  font-family: "ProximaNova-Bold";
  src: local("ProximaNova-Bold"),
  url("./assets/fonts/ProximaNova-Bold.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "ProximaNova-Regular";
  src: local("ProximaNova-Regular"),
  url("./assets/fonts/ProximaNova-Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "ProximaNova-Semibold";
  src: local("ProximaNova-Semibold"),
  url("./assets/fonts/ProximaNova-Semibold.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'ProximaNova-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'ProximaNova-Regular', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block-desktop {
  display: none;
}
.block-mobile {
  display: block;
}
#bottom-nav .bottom-nav-item {
  flex: 1;
}
#bottom-nav > div > div {
  flex: 1;
  padding-top: 14px;
  padding-bottom: 10px;
  align-items: center;
}
#bottom-nav > #help-btn-desktop {
  display: none;
}
@media (min-width: 768px) {
  .rowReverse{
    flex-direction: row-reverse;
  }
  .flexDirection{
    flex-direction: column;
  }
  div:has(#bottom-nav) {
    flex-direction: row-reverse;
  }
  #bottom-nav > div {
    flex-direction: column;
    background-color: transparent;
    border-top-width: 0;
  }
  #bottom-nav > div > div {
    align-items: center;
    flex-direction: row;
    padding-left: 40px;
    height: 49px;
    margin-bottom: 10px;
  }
  #bottom-nav > #help-btn-desktop {
    display: flex;
    padding: 20px 18px 0 18px;
  }
  div:has(#headerDK){
    flex-direction: column;
  }
  #mobile-header {
    display: none;
  }
  .block-desktop {
    display: block;
  }
  .block-mobile {
    display: none;
  }
}
