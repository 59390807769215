#headerDK {
    display: none;
}

@media (min-width: 768px) {
    #headerDK {
        padding: 28px 20px 28px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border-bottom-width: 1px;
        border-bottom-color: #9299BE;
        border-style: solid;
        border-top-width: 0;
        border-right-width: 1px;
        border-left-width: 1px;
        border-left-color: #F6F6FC;
        border-right-color: #F6F6FC;
    }
}

.logoLeft {
    width: 200px;
}

.logoLeft img {
    width: 105px;
    height: 56px;
}
